<script setup>
import { computed } from 'vue';
import { product } from '../state';

const catalog = computed(() => product.value.catalogs.length ? product.value.catalogs[0] : {})
const defaultUnit = computed(() => {
  const unitByInventory = (product.value.inventory_unit || []).find(i => i.numerator === 1 && i.denumerator === 1)

  return unitByInventory?.unit || catalog.value.uom || catalog.value.price?.uom || ''
})
</script>

<template>
  <a-row>
    <a-col class="mb-3" :span="6">
      <div class="title-detail d-flex justify-content-start mb-2">
        Produk Fisik
      </div>
    </a-col>
    <a-col class="mb-4" :span="18">
      <div class="text-left mb-2">
        : {{ product.details.is_physical ? 'Ya' : 'Tidak' }}
      </div>
    </a-col>
    <div v-if="product.details.is_physical">
      <a-col class="mb-2" :span="24">
        <div class="font-weight-semibold d-flex justify-content-start mb-2">
          Satuan Dasar
        </div>
      </a-col>
      <a-col class="mb-2" :span="6">
        <div class="title-detail d-flex justify-content-start mb-2">
          Satuan Produk
        </div>
      </a-col>
      <a-col class="mb-2" :span="18">
        <div class="text-left mb-2">
          : {{ defaultUnit }}
        </div>
      </a-col>
      <a-col class="mb-4" :span="6">
        <div class="title-detail d-flex justify-content-start mb-2">
          Berat Produk
        </div>
      </a-col>
      <a-col class="mb-4" :span="18">
        <div class="text-left mb-2">
          : {{ catalog.weight }} <span>gram</span>
        </div>
      </a-col>
      <a-col class="mb-2" :span="24">
        <div class="font-weight-semibold d-flex justify-content-start mb-2">
          Ukuran Paket
        </div>
      </a-col>
      <a-col class="mb-2" :span="6">
        <div class="title-detail d-flex justify-content-start mb-2">
          Lebar
        </div>
      </a-col>
      <a-col class="mb-2" :span="18">
        <div class="text-left mb-2">
          : {{ catalog.width }} mm
        </div>
      </a-col>
      <a-col class="mb-2" :span="6">
        <div class="title-detail d-flex justify-content-start mb-2">
          Panjang
        </div>
      </a-col>
      <a-col class="mb-2" :span="18">
        <div class="text-left mb-2">
          : {{ catalog.length }} mm
        </div>
      </a-col>
      <a-col class="mb-3" :span="6">
        <div class="title-detail d-flex justify-content-start mb-2">
          Tinggi
        </div>
      </a-col>
      <a-col class="mb-3" :span="18">
        <div class="text-left mb-2">
          : {{ catalog.height }} mm
        </div>
      </a-col>
    </div>
  </a-row>
</template>
