<script setup>
import { useAxiosClient } from '@/composable/useFetch';
import { useStore } from '@/store';
import { computed } from 'vue';
import { useRoute } from 'vue-router/composables';
import { useCurrentBusiness } from '@/composable/useCurrentBusiness';
import FormProduct from '@/components/Store/WebCommerce/Product/FormProduct';

const route = useRoute()
const store = useStore()
const business = useCurrentBusiness()

const id = computed(() => route.params.id)

const { data: product, error, isLoading, isFinished: isReady } = useAxiosClient(
  `/channel/store/product/query/${id.value}`, 
  {
    params: {
      channel_id: route.params.channelId,
    },
    headers: {
      'user-id': store.user?.id,
      'business-id': route.query.business_id,
    },
  },
  {
    immediate: true,
    shallow: true,
    initialData: {
      data: null,
    },
  },
)


</script>

<template>
  <div>
    <template v-if="error">
      <a-result :status="String(error.code)" :title="error.code" :sub-title="error.message">
        <template #extra>
          <a-button type="primary" @click="$router.back()">
            Back to previous page
          </a-button>
        </template>
      </a-result>
    </template>


    <FormProduct v-else :value="product.data" edit-form />
  </div>
</template>
