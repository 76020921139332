import { computed, onMounted, onUnmounted, ref, unref, watch } from 'vue';
import { useStore } from '@/store';
import { useRoute } from 'vue-router/composables';
import { getProductStoreCategoriesV2 } from '@/api/channels/store'


const masterCategoryTree = ref([])
const channelCategoryTree = ref([])
const isLoading = ref(false)

const cleanCategoryTree = (categories) => {
  return (categories || []).map(({ children, ...category}) => {
    const item = {
      ...category,
      value: category.id,
      label: category.title || category.name,
      id: category.id,
      title: category.title || category.name,
    }
    
    if (children.length) {
      item.children = cleanCategoryTree(children)
    }

    return item
  })
}

export const useCategory = (salesChannelId, channelId) => {
  const store = useStore()
  const route = useRoute()

  const fetch = async () => {
    if (!salesChannelId && !channelId) return
    
    isLoading.value = true
    const [{ data: responseChannel }] = await Promise.all([
      getProductStoreCategoriesV2({
        business_id: route.query.business_id,
        user_id: store.state.user?.id,
        params: {
          channel_id: channelId,
        },
      }),

    ])

    channelCategoryTree.value = cleanCategoryTree(responseChannel.data)
    masterCategoryTree.value = cleanCategoryTree([])
    isLoading.value = false
  }
  
  onMounted(() => {
    if (!isLoading.value) fetch()
  })

  const flattedChannelCategories = computed(() => {
    const categories = [...channelCategoryTree.value]
    const parseToFlat = (data = channelCategoryTree.value ) => {
      (data || []).forEach(({ children, ...category }) => {
        categories.push(category)
        if (children) parseToFlat(children)
      })
    }

    parseToFlat()
    return categories
  })

  const findChannelTreeById = computed(() => (id) => {
    const tree = []

    const find = (id) => {
      if (!id) return

      const match = flattedChannelCategories.value.find(category => Number(category.id) === Number(id))
      if (match) {
        tree.unshift(match)
        find(match.parent_id)
      }
    }

    find(id)

    return tree
  })


  const flattedMasterCategories = computed(() => {
    const categories = [...masterCategoryTree.value]
    const parseToFlat = (data = masterCategoryTree.value ) => {
      (data || []).forEach(({ children, ...category }) => {
        categories.push(category)
        if (children) parseToFlat(children)
      })
    }

    parseToFlat()
    return categories
  })

  const findMasterTreeById = computed(() => (id) => {
    const tree = []

    const find = (id) => {
      if (!id) return

      const match = flattedMasterCategories.value.find(category => Number(category.id) === Number(id))
      if (match) {
        tree.unshift(match)
        find(match.parent_id)
      }
    }

    find(id)

    return tree
  })

  const findMasterTreeByChannelCategoryId = computed(() => (id) => {
    const tree = []

    const find = (id) => {
      if (!id) return

      const match = flattedMasterCategories.value.find(category => tree.length ? Number(category.id) === Number(id) : Number(category.category_channel_id) === id)
      if (match) {
        tree.unshift(match)
        find(match.parent_id)
      }
    }

    find(id)

    return tree
  })

  return {
    tree: channelCategoryTree,
    flat: flattedChannelCategories,
    findTreeById: findChannelTreeById,

    channelCategoryTree,
    flattedChannelCategories,
    findChannelTreeById,

    masterCategoryTree,
    flattedMasterCategories,
    findMasterTreeById,
    findMasterTreeByChannelCategoryId,

  }
}
