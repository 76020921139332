<script setup>
import { computed } from 'vue';
import { product } from '../state';
import FormItem from '@/components/Input/FormItem.vue';
import PriceInput from '../Price/PriceInput.vue';
import Discount from './Discount/index.vue';
import CommissionInput from './Commission/CommissionInput.vue';
import ProductCollectionInput
  from "@/components/Store/WebCommerce/Product/FormProduct/ProductCollectionInput/ProductCollectionInput.vue";

const props = defineProps({
  editForm: {
    type: Boolean,
    default: () => false,
  },
  readonly: {
    type: Boolean,
    default: () => false,
  },
})

const item = computed(() => product.value.catalogs[0])

</script>

<template>
  <div>
    <div v-if="!item && readonly">
      <a-skeleton />
    </div>
    <a-row v-else-if="readonly">
      <a-col class="mb-2" :span="6">
        <div class="title-detail d-flex justify-content-start mb-2">
          {{ $t('SKU') }}
        </div>
      </a-col>
      <a-col class="mb-2" :span="18">
        <div class="mb-2">
          : <span class="ml-2 font-weight-bolder">{{ item.sku }}</span>
        </div>
      </a-col>
      <a-col class="mb-2" :span="6">
        <div class="title-detail d-flex justify-content-start mb-2">
          Harga
        </div>
      </a-col>
      <a-col class="mb-2" :span="18">
        <div class="mb-2">
          : <span class="ml-2 font-weight-bolder">{{ item.price.price_sales | currency }} / {{ item.price.uom }}</span>
        </div>
      </a-col>
    </a-row>

    <div v-else>
      <FormItem rules="required|no_space" :label="$t('SKU')">
        <a-input 
          v-model="item.sku"
          class="h-48px"
          :disabled="editForm"
        />
      </FormItem>


      <FormItem rules="required" :label="$t('product.price')">
        <PriceInput 
          v-model="item.price" 
          :editable-currency="!editForm"
          :editable-unit="!editForm"
        />
      </FormItem>
    </div>
    
    <Discount v-model="item.promotions" :price="item.price" :readonly="readonly" />

    <CommissionInput v-model="item.commissions" :readonly="readonly" :price="item.price" class="mt-3" />

    <ProductCollectionInput v-if="product.product_id" :catalog="item" :product-id="product.product_id" class="mt-3" />
  </div>
</template>
