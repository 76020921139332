<script setup>
import { computed, defineComponent, ref } from 'vue'
import { useCurrency } from '@/composable/useCurrency'
import FormItem from '@/components/Input/FormItem.vue'
import { ValidationProvider } from 'vee-validate';
import UnitSelector from '@/components/Product/ProductForm/TypeAndPrice/UnitSelector.vue';
import { useUnits } from '@/components/Product/ProductForm/useUnits';

const props = defineProps({
  value: {
    type: Object,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    default() {
      return this.$t('utils.price')
    },
  },
  editableUnit: {
    type: Boolean,
    default: false,
  },
  editableCurrency: {
    type: Boolean,
    default: false,
  },
})
const price = computed(() => props.value)
const { filteredUnitAsOptions } = useUnits()
const { currencies, format, parseLocaleNumber } = useCurrency()

const priceValidationProvider = ref(null)
const error = computed(() => priceValidationProvider.value?.errors[0])

</script>

<template>
  <div style="max-width: 600px" class="addon-dark ant-input-group-wrapper h-48px">
    <div class="ant-input-wrapper ant-input-group" :class="{ 'has-error': error }">
      <div class="ant-input-group-addon bg-white">
        <FormItem
          tag="div"
          rules="required"
          name="currency"
          style="width: 80px; margin: 0 -11px; border-radius: 3px 0 0 3px"
        >
          <a-select 
            v-model="price.currency_id" 
            :options="currencies.map(({ id, symbol }) => ({ value: id, label: symbol }))" 
            class="w-100 text-dark" 
            :disabled="!editableCurrency"
          />
        </FormItem>
      </div>
      <ValidationProvider ref="priceValidationProvider" tag="div" rules="required" :name="label">
        <a-input-number
          v-model.number="price.price_sales"
          :disabled="disabled"
          :formatter="value => format(value)"
          :parser="value => parseLocaleNumber(value)"
          class="h-48px w-100 required price-input-number-input"
        />
      </ValidationProvider>
      <div class="ant-input-group-addon">
        <FormItem
          v-if="editableUnit"
          tag="div"
          rules="required"
          style="width: 135px; margin: 0 -11px"
          class="text-white"
        >
          <UnitSelector v-model="price.uom" @change="val => $emit('changeuom', val)" />
        </FormItem>
        <div v-else class="text-white text-left px-3" style="width: 85px; margin: 0 -11px">
          {{ price.uom }}
        </div>
      </div>
    </div>
    <div v-if="error" class="ant-form-explain text-danger">
      {{ error }}
    </div>
  </div>
</template>
