import { shallowRef, ref, onMounted } from 'vue';
import { useStore } from '@/store';
import { useRoute } from 'vue-router/composables';
import { getProductBrands } from '@/api/channels/store'

/**
 * @type {import('vue').ShallowRef<import('./$types').Brand[]>}
 */
const state = shallowRef([]);
const isLoading = ref()

export function useBrand(channelId) {
  const store = useStore()
  const route = useRoute()

  const fetch = async () => {
    isLoading.value = true
    const { data: response } = await getProductBrands({
      business_id: route.query.business_id,
      user_id: store.state.user?.id,
      params: {
        channel_id: channelId,
        limit: 1000,
      },
    })

    state.value = (response.data || []).map(brand => ({
      value: brand.id,
      key: brand.id,
      label: brand.brand_title,
      title: brand.brand_title,
    }))
    isLoading.value = false
  }

  onMounted(() => {
    if (!isLoading.value) fetch()
  })

  return {
    state,
    isLoading,
  }
}
