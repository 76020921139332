<script setup>
import FormItem from '@/components/Input/FormItem.vue';
import { ValidationProvider } from 'vee-validate';
import { ref } from 'vue';
import PriceInput from '../../Price/PriceInput.vue';
import { useCurrency } from '@/composable/useCurrency';
import Discount from '../Discount/index.vue';
import { product } from "@/components/Store/WebCommerce/Product/FormProduct/state";
import ProductCollectionInput
  from "@/components/Store/WebCommerce/Product/FormProduct/ProductCollectionInput/ProductCollectionInput.vue";


const props = defineProps({
  catalog: {
    type: Object,
    required: true,
  },
  defaultCollapsed: {
    type: Boolean,
    default: () => true,
  },
  variants: {
    type: Object,
  },
  editForm: {
    type: Boolean,
    default: () => false,
  },
  readonly: {
    type: Boolean,
    default: () => false,
  },
})

const show = ref(props.defaultCollapsed ? props.catalog.id : null)

const { format } = useCurrency()
</script>



<template>
  <a-collapse :active-key="show" expand-icon-position="right" :expand-icon="null" :class="{ collapsed: !show }">
    <div class="variant-table--summary">
      <div v-for="(_option, type) in variants" :key="type" class="option-name">
        {{ catalog.variant[type] }}
      </div>
      <div class="ml-auto">
        <span v-if="catalog.sku">
          <a-tooltip :title="catalog.sku">
            {{ catalog.sku }}
          </a-tooltip>
        </span>
        <span v-else style="font-size: 80%; color: #bbb">
          (Not set)
        </span>
      </div>
      <div class="actions">
        <a-switch
          :checked="true"
          size="small"
          disabled
        />
      </div>
      <div class="caret">
        <button type="button" @click="show = show ? null : catalog.id">
          <a-icon type="caret-right" :rotate="!!show ? 90 : 0" />
        </button>
      </div>
    </div>

    <a-collapse-panel :key="catalog.id" class="variant-table--item" :show-arrow="true" force-render>
      <div class="variant-table--detail">
        <div class="d-flex">
          <!-- <div class="mr-4" style="width: 125px"> -->
          <!-- <ValidationProvider
              v-slot="{ errors }"
              :rules="{ required: catalog.status }"
              :custom-messages="{
                required: 'Pilih minimal 1 gambar produk'
              }"
              immediate
              :name="`Gambar ${value.title}`"
              tag="div"
            >
              <SelectPhoto
                v-model="catalog.images"
                :images="images"
                :class="{ 'has-feedback has-error': errors.length }"
              />
              <div v-if="errors.length" class="text-danger text-small mt-2 ant-form-explain">
                {{ errors[0] }}
              </div>
            </ValidationProvider> -->
          <!-- </div> -->
          <div class="w-100">
            <a-row>
              <a-col :span="4">
                <div class="title-detail d-flex justify-content-start mb-2">
                  Nama
                </div>
              </a-col>
              <a-col :span="18">
                <div class="text-left mb-2">
                  : <strong>{{ catalog.title }} </strong>
                </div>
              </a-col>
            </a-row>

            <template v-if="readonly">
              <a-row>
                <a-col :span="4">
                  <div class="title-detail d-flex justify-content-start mb-2">
                    SKU
                  </div>
                </a-col>
                <a-col :span="18">
                  <div class="text-left mb-2">
                    : {{ catalog.sku }}
                  </div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="4">
                  <div class="title-detail d-flex justify-content-start mb-2">
                    {{ $t('product.price') }}
                  </div>
                </a-col>
                <a-col :span="18">
                  <div class="text-left mb-2">
                    : {{ format(catalog.price.price_sales, catalog.price.currency_code) }} / {{ catalog.price.uom }}
                  </div>
                </a-col>
              </a-row>
            </template>

            <template v-if="!readonly">
              <a-row type="flex" :gutter="16" class="">
                <a-col :xl="{ span: 8 }">
                  <FormItem
                    :rules="{ required: catalog.status, no_space: true, excluded: [] }"
                    :custom-messages="{
                      excluded: 'SKU tidak boleh sama dengan varian lainnya'
                    }"
                    :label="$t('SKU')"
                    :name="`SKU [${catalog.title}]`"
                  >
                    <a-input v-model="catalog.sku" :disabled="$route.query.master_product ? true : false" class="h-48px" />
                  </FormItem>
                </a-col>
                <a-col :xl="{ span: 10 }">
                  <FormItem rules="required" :label="$t('product.price')">
                    <PriceInput
                      v-model="catalog.price" 
                      :editable-currency="!editForm"
                      :editable-unit="!editForm"
                    />
                  </FormItem>
                </a-col>
              </a-row>
            </template>

            <Discount v-model="catalog.promotions" :price="catalog.price" :readonly="readonly" />

            <ProductCollectionInput v-if="product.product_id" :catalog="catalog" :product-id="product.product_id" class="mt-3" />


            <!-- <Price
              v-model="catalog.price"
              :edit="edit"
              :catalog="catalog"
              :submit="submit"
              :flag="flag"
              :vat-type="product.detail.vat_type"
              :business="business"
              :units="units"
              :unit="fixUnit ? catalog.price.uom : null"
              :label="`Harga Produk ${catalog.sku}`"
              @changeuom="val => $emit('changeuom', val)"
              @setPriceArea="(e) => $emit('setPriceArea', e)"
            /> -->
          </div>
        </div>
      </div>
    </a-collapse-panel>
  </a-collapse>
</template>

<style scoped lang="scss">
.caret {

  >div,
  >button {
    background-color: transparent;
    border: 0;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

  }
}

.variant-table--item::v-deep {
  .ant-collapse-header {
    display: none;
  }
}
</style>
