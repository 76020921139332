import { useAxiosClient } from '@/composable/useFetch';
import { useStore } from '@/store';
import { computed, reactive, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router/composables';

/** @type {import('./type').Product} */
export const DEFAULT_VALUE = {
  product_id: null,
  catalogs: [
    {
      factory_sku: '',
      height: 10,
      weight: 10,
      length: 10,
      width: 10,
      price: {},
      promotions: [],
      uom: 'PCS',
      sku: '',
      title: '',
      variant: {},
      commissions: {},
    },
  ],
  details: {
    is_physical: true,
    description: [],
    title: '',
    category_tree: [],
    master_category_tree: [],
    master_options: [],
    option_attributes: {},
  },
  inventory_unit: [],
  images: [],
}

/**
 * @type {import('vue').Ref<import('./type').Product>}
 */
export const product = ref({
  ...DEFAULT_VALUE,
})

export function setToDefaultValue() {
  product.value = {
    ...DEFAULT_VALUE,
  }
}

export const commission = reactive({
  loading: true,
  error: null,
  data: [],
})
