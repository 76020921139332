import { computed, getCurrentInstance, onMounted, ref, shallowRef } from 'vue';
import { getResellerLevel } from '@/api/channels/store'
import { useStore } from '@/store';

const isLoading = ref(false)
const data = shallowRef({})

export const useResellerLevel = (businessId, channelId) => {
  const key = `${businessId}_${channelId}`

  /** @type {import('vue').ComputedRef<any[]>} */
  const levels = computed(() => data.value[key] || [])

  const store = useStore()
  const { $message } = getCurrentInstance().proxy


  async function execute(force = false) {
    if (!levels.value.length || !force) {
      isLoading.value = true
      
      try {
        const response = await getResellerLevel({
          business_id: businessId,
          user_id: store.state.user?.id,
          params: {
            channel_id: channelId,
          },
        })

        data.value = {
          ...data.value,
          [key]: response.data.data || [],
        }
      } catch (err) {
        $message.error(err?.response?.data?.message || err?.response?.message || err?.message)
        throw err;
      } finally {
        isLoading.value = false
      }
    }
  }

  onMounted(() => execute())

  return {
    levels,
    isLoading,
  }
}
