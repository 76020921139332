import { useRoute } from 'vue-router/composables';
import { useStore } from '../store';
import { computed } from 'vue';

export const useCurrentBusiness = () => {
  const store = useStore()
  const route = useRoute()

  const business = computed(() => {
    if (!route.query.business_id) return null
    
    const data = (store.state.user?.businessList || []).find(business => business.business_id === route.query.business_id)

    if (!data) return null

    return {
      handle: data.business_name,
      title: data.business_title,
      id: route.query.business_id || data.business_id,
    }
  })

  return business
}
