<script setup>
import FormItem from '@/components/Input/FormItem.vue';
import { product } from '../state';
import { markRaw, onMounted, onUnmounted, readonly, ref, shallowRef, unref } from 'vue';
import { ValidationObserver } from 'vee-validate';
import { useCategory } from '../../../composables/category';
import { useBrand } from '../../../composables/brands';
import Checkbox from '@/components/Input/Checkbox.vue';
import { useRoute } from 'vue-router/composables';
import TextEditor from '@/components/Input/TextEditor/index.vue'

const route = useRoute();

/** @type {import('vue').Ref<typeof ValidationObserver>} */
const validationObserver = ref(null);

/** @type {import('vue').Ref<import('../type').Product} */
const tmpValue = ref();

onMounted(() => {
  tmpValue.value = JSON.parse(JSON.stringify(product.value))
})

const emit = defineEmits([
  'discard',
  'save',
])

function discard() {
  if (tmpValue.value) {
    const oldDetailValue = tmpValue.value.details

    product.value.details.title = oldDetailValue.title
    product.value.details.description = [...oldDetailValue.description]
    product.value.details.brand_id = oldDetailValue.brand_id
    product.value.details.imported = oldDetailValue.imported
    product.value.details.condition = oldDetailValue.condition
    product.value.details.category_id = oldDetailValue.category_id
    product.value.details.category_name = oldDetailValue.category_name
    product.value.details.category_slug = oldDetailValue.category_slug
    product.value.details.category_tree = oldDetailValue.category_tree
    product.value.details.master_category_tree = oldDetailValue.master_category_tree
  }

  emit('discard')
}

const { tree, findChannelTreeById, masterCategoryTree, findMasterTreeByChannelCategoryId } = useCategory()
// const onCategorySelected = (_values, objects) => {
//   product.value.details.master_category_tree = (objects || []).map(({ id, title, parent_id }) => ({ id, title, parent_id}))

//   const category = objects[objects.length - 1]
//   let channelCategoryTree = findChannelTreeById.value(category.category_channel_id)

//   if (channelCategoryTree.length) {
//     const channelCategory =  channelCategoryTree[channelCategoryTree.length - 1] 

//     product.value.details.category_id = channelCategory.id
//     product.value.details.category_name = channelCategory.title
//     product.value.details.category_slug = channelCategory.slug
//     product.value.details.category_tree = channelCategoryTree

//   }
// }

const onCategoryChannelSelected = (values, objects) => {
  const category = objects[objects.length - 1]

  product.value.details.category_id = category.id
  product.value.details.category_name = category.title
  product.value.details.category_slug = category.slug
  product.value.details.category_tree = (objects || []).map(({ id, title, parent_id }) => ({ id, title, parent_id}))

  const masterCategory = findMasterTreeByChannelCategoryId.value(category.id) || []
  product.value.details.master_category_tree = masterCategory
}


const { state: brands } = useBrand(route.params.channelId)

const preventSpecialCharacters = (event) => {
  const forbiddenCharacters = /[:/?#\[\]@!$&'()*+,;=\s]/
  if (forbiddenCharacters.test(event.key)) {
    event.preventDefault()
  }
}

const replaceSpecialCharacters = () => {
  product.value.details.slug = product.value.details.slug.replace(/[:/?#\[\]@!$&'()*+,;=\s]/g, '')
}

async function save() {
  if (await validationObserver.value.validate()) {
    emit('save')
  } else {
    /** @type {HTMLElement} */
    const errorField = validationObserver.value.$el.querySelector('.has-feedback.has-error')

    console.log({ errorField, offset: errorField.getBoundingClientRect().top, y : window.scrollY })
    if (errorField) window.scrollTo({
      top: window.scrollY + errorField.getBoundingClientRect().top - 120,
      left: 0,
      behavior: 'smooth',
    })
  }
}
</script>

<template>
  <ValidationObserver ref="validationObserver" slim>
    <FormItem
      :name="$t('product.product_name')"
      :label="$t('product.product_name')"
      vid="title"
      rules="required"
      class="pcaFormItemLabel"
    >
      <a-input
        v-model="product.details.title"
        class="h-48px"
        focus
      />
    </FormItem>

    <FormItem
      :name="$t('product.slug')"
      :label="$t('product.slug')"
      vid="title"
      class="pcaFormItemLabel"
      :rules="{
        required: true,
        regex: /^[a-z0-9]+(?:-[a-z0-9]+)*$/
      }"
    >
      <a-input
        v-model="product.details.slug"
        class="h-48px"
        @blur="replaceSpecialCharacters"
        @change="replaceSpecialCharacters"
        @keypress="preventSpecialCharacters"
      />
    </FormItem>
    <div class="text-muted small mb-4 mt-n2" style="line-height: 1.5;">
      Slug digunakan sebagai URL identifikasi untuk produk ini. Slug hanya boleh berisi huruf, angka dan tanda kurang ( - )
    </div>

    <FormItem
      :name="$t('product.status')"
      :label="$t('product.status')"
      vid="status"
      rules="required"
    >
      <a-switch
        size="large"
        :checked="product.details.status === 'ACTIVE'"
        @change="(checked) => product.details.status = checked ? 'ACTIVE' : 'NOT_ACTIVE'"
      />
      <span class="ml-4 text-muted small">Aktifkan produk jika Anda ingin membuat produk ini dapat dibeli oleh pembeli</span>
    </FormItem>

    <!-- <FormItem
      :label="$t('product.categories')"
      :name="$t('product.categories')"
      vid="category"
      rules="required"
    >
      <a-input-group compact class="">
        <a-cascader
          :value="(product.details.master_category_tree || []).map(cat => cat.id)"
          :field-names="{ label: 'title', value: 'id', children: 'children' }"
          class="text-left w-100 h-48px"
          :options="masterCategoryTree"
          placeholder=""
          :allow-clear="false"
          @change="onCategorySelected"
        />
      </a-input-group>
    </FormItem> -->


    <FormItem
      :label="$t('product.categories')"
      :name="$t('product.categories')"
      vid="category"
      rules="required"
    >
      <a-input-group compact class="">
        <a-cascader
          :value="(product.details.category_tree || []).map(cat => cat.id)"
          :field-names="{ label: 'title', value: 'id', children: 'children' }"
          class="text-left w-100 h-48px"
          :options="tree"
          placeholder=""
          allow-clear
          @change="onCategoryChannelSelected"
        />
        <!-- <div class="mt-2">
          <span class="text-muted">Channel kategori akan menampilkan kategori sesuai dengan kategori yang dipetakan untuk webcommerce</span>
        </div> -->
      </a-input-group>
    </FormItem>

    <FormItem
      :name="$t('product.brand')"
      :label="$t('product.brand')"
      vid="brand"
    >
      <a-select
        v-if="brands.length > 0"
        v-model="product.details.brand_id"
        :options="brands"
        size="large"
        class="field-select"
        placeholder="Cari & Pilih Brand Produk"
        allow-clear
        show-search
      >
        <a-select-option v-for="brand in brands" :key="brand.id">
          {{ brand.title }}
        </a-select-option>
      </a-select>
      <a-input
        v-else
        v-model="product.details.brand_id"
        class="h-48px"
      />
    </FormItem>

    <a-row class="mt-5">
      <a-col :sm="{ span: 8 }">
        <div class="mb-3">
          <label class="d-block font-weight-semibold mb-3">{{ $t('product.item_condition') }}</label>
          <FormItem>
            <Checkbox
              v-model="product.details.condition"
              check-value="USED"
              uncheck-value="NEW"
              :label="$t('product.used_condition')"
              class="ml-2"
            />
          </FormItem>
        </div>
      </a-col>
      <a-col :sm="{ span: 8 }">
        <div class="mb-3">
          <label class="d-block font-weight-semibold mb-3">{{ $t('product.importLabel') }}</label>
          <FormItem>
            <Checkbox 
              v-model="product.details.imported"
              :label="$t('product.import')"
              class="ml-2"
            />
          </FormItem>
        </div>
      </a-col>
    </a-row>
    

    <div 
      v-for="description in product.details.description" 
      :key="description.lang_code" 
      class="description-section"
    >
      <div class="description__title">
        <div>
          {{ $t('language') }} : <span class="language">{{ description.lang_code }}</span>
        </div>
      </div>
      <div class="description__body">
        <FormItem 
          :label="$t('product.short_description')"
        >
          <TextEditor
            v-model="description.short"
            :placeholder="$t('product.write_something')"
          />
        </FormItem>

        <FormItem 
          :label="$t('product.description')"
        >
          <TextEditor
            v-model="description.long"
            :placeholder="$t('product.write_something')"
          />
        </FormItem>
      </div>
    </div>

    <div 
      class="mt-4 d-flex justify-content-end"
      :style="{
        margin: '-1.6rem',
        background: '#f8f9fa',
        padding: '0.5rem 1.5rem',
      }"
    >
      <a-button type="link" class="text-muted mr-4" size="large" @click="discard">
        Discard
      </a-button>

      <a-button type="primary" class="" size="large" @click="save">
        {{ $t('utils.save') }}
      </a-button>
    </div>
  </ValidationObserver>
</template>

<style lang="scss" scoped>
.description-section {
  border: 1px solid #efefef;

  .description__body {
    padding: 1rem;
  }

  .description__title {
    padding: .5rem 1rem;
    background: #f8f9fa;
    display: flex;
    align-items: center;
  }

  .language {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
  }
}

.field-select::v-deep {
  .ant-select-selection__rendered {
    line-height: 38px;
    font-size: 14px;
  }

  .ant-select-selection__placeholder {
    color: #999;
  }
}

</style>
