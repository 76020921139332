<script setup lang="ts">
import {useProductCollection} from "@/components/Store/WebCommerce/composables/productCollection";
import {useRoute} from "vue-router/composables";
import {computed, ref, watch} from "vue";

const props = defineProps({
  catalog: {
    type: Object,
    required: true,
  },
  productId: {
    type: [String, Number],
    required: true,
  },
})

const route = useRoute()

const { catalogCollection, productSections, addToCollection, remove } = useProductCollection(route.params.channelId)
const collections = computed(() => catalogCollection(props.catalog.id))
const selectableCollectionType = computed(() => {
  const selected = collections.value.map(item => item.type)
  const options = []

  for (const type in productSections.value) {
    if (!selected.includes(type))  options.push({
      type,
      title: productSections.value[type].title,
    })
  }

  return options
})


const addToCollectionForm = ref({
  type: '',
})
const showAddToCollectionDialog = ref(false)
watch(showAddToCollectionDialog, () => addToCollectionForm.value.type = selectableCollectionType.value.length ? selectableCollectionType.value[0].type : '')
const addToCollectionLoading = ref(false)

const addToCollectionHandle = async () => {
  addToCollectionLoading.value = true

  try {
    await addToCollection(props.productId, props.catalog.id, addToCollectionForm.value.type)
  } finally {
    addToCollectionLoading.value = false
    showAddToCollectionDialog.value = false
  }
}
</script>

<template>
  <div>
    <div class="mb-2 font-weight-bold">
      {{ $t('product.collection.title') }}
    </div>
    <div v-if="collections.length" class="collection-tags">
      <template v-for="collection in collections">
        <div :key="collection.type">
          <span>{{ collection.title }}</span>
          <a-popconfirm @confirm="() => remove(collection.type, productId, catalog.id)">
            <template #title>
              {{ $t('product.collection.confirm.delete') }}
            </template>
            <button type="button">
              <a-icon type="close" />
            </button>
          </a-popconfirm>
        </div>
      </template>
    </div>

    <a-button v-if="selectableCollectionType.length" class="add-to-collection" icon="plus" @click="showAddToCollectionDialog = true">
      {{ $t('product.collection.add-to-collection') }}
    </a-button>

    <a-modal
      v-model="showAddToCollectionDialog"
      :title="$t('product.collection.add-to-collection')"
      :confirm-loading="addToCollectionLoading"
      @ok="() => addToCollectionHandle()"
    >
      <div>
        <label>{{ $t('product.collection.type-label') }}</label>
        <a-select
          v-model="addToCollectionForm.type"
          class="w-100"
        >
          <a-select-option v-for="type in selectableCollectionType" :key="type.type">
            {{ type.title }}
          </a-select-option>
        </a-select>
      </div>
    </a-modal>
  </div>
</template>

<style scoped lang="scss">
.collection-tags {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;

  > * {
    display: flex;
    align-items: center;
    border: solid 1px;
    border-color: var(--kit-color-primary);
    border-radius: .25rem;
    color: var(--kit-color-primary);

    > span {
      padding: .25rem .25rem .25rem 1rem;
    }

    button {
      color: var(--kit-color-primary);
      background: transparent;
      border: 0;
      padding: .25rem .75rem .25rem .25rem;
      margin-bottom: -1px;
    }
  }
}

.add-to-collection {
  border: none !important;
  padding: 0;
  color: #999;
}
</style>