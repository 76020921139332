<script setup>
import FormItem from '@/components/Input/FormItem.vue';
import { useResellerLevel } from '@/components/Store/WebCommerce/composables/useResellerLevel';
import { useCurrency } from '@/composable/useCurrency';
import { computed, defineProps, PropType, ref, shallowRef, watch } from 'vue';
import { useRoute } from 'vue-router/composables';

const props = defineProps({
  value: {
    type: Object,
  },
  price: {
    type: Object,
  },
  readonly: {
    type: Boolean,
    default: () => false,
  },
})

const emit = defineEmits(['input'])

const route = useRoute()
const { levels, isLoading } = useResellerLevel(route.query.business_id, route.params.channelId)

const { format, parseLocaleNumber } = useCurrency()

function getValue(level) {
  return props.value[level.id]?.schema?.value || level.default_discount || 0
}

function calculateValue(level) {
  return (Number(getValue(level))) / 100 * Number(props.price.price_sales)
}

function setValue(level, inputValue) {
  const DEFAULT_VALUE = (levelId, value) => ({
      level_id: levelId || level.id,
      schema: {
        type: 1,
        value: value || level.default_discount,
      },
    })

  const values = { 
    ...(
      Object.keys(props.value).length 
      ? props.value 
      : levels.value.reduce((map, value) => {
        map[value.id] = DEFAULT_VALUE(value.id, value.default_discount)
        return map
      }, {})
    ),
  }

  if (!values[level.id]) {
    values[level.id] = DEFAULT_VALUE()
  }

  const value = values[level.id]

  value.schema.value = inputValue
  emit('input', values)
}
</script>

<template>
  <div>
    <div class="mb-2 font-weight-bold">
      Komisi Dropshipper
    </div>
    <div v-if="readonly" class="commission-list readonly">
      <dl v-for="level in levels" :key="level.level_id" class="">
        <dt>{{ level.level_name }}</dt>
        <dd class="d-flex">
          <span>{{ getValue(level) }}%</span>
          <span class="mx-3 text-muted small">
            <a-icon type="arrow-right" />
          </span>
          <span class="text-muted small">{{ format(calculateValue(level), price.currency_code) }}</span>
        </dd>
      </dl>
    </div>
    <div v-else class="commission-list">
      <div v-for="level in levels" :key="level.level_id">
        <FormItem
          :label="level.level_name"
          :name="`Komisi ${level.level_name}`"
        >
          <div class="ant-input-group-wrapper">
            <div class="ant-input-wrapper ant-input-group" :class="{ 'has-error': error }">
              <a-input-number :value="getValue(level)" class="" prefix="%" @change="value => setValue(level, value)" />
              <div class="ant-input-group-addon">
                %
              </div>
            </div>
          </div>
        </FormItem>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ant-input-group::v-deep .ant-input-number {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.commission-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  dl {
    margin-bottom: 0;
  }
}

.commission-list.readonly {
  gap: 1rem 3rem;
}
</style>
